.home-assistant-page {
  header {
    img.logo {
      position: absolute;
      top: 4px;
      left: 48.9%;
      transform: translateX(-50%);
      width: 94.18px;
      height: 109.85px;
      z-index: 9999;
      cursor: pointer;
    }
    .menu {
      cursor: pointer;
      padding: 10px;
      position: absolute;
      top: 23.63px;
      right: 33px;
      z-index: 103;

      .dropdown {
        position: relative;
        display: inline-block;

        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f9f9f9;
          width: 160px;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
          padding: 12px 16px;
          z-index: 1;
          right: 0;
          text-align: center;

          button {
            display: block;
            background-color: transparent;
            border: none;
            color: $base;
            cursor: pointer;
            border-bottom: 1px solid #ddd;
            width: 100%;
            padding: 20px 0;

            &.nav-active {
              color: $red;
              font-weight: 700;
            }

            &:last-child {
              border-bottom: none;
            }

            &:hover,
            &:focus {
              color: $orange;
            }
          }

          &.open {
            display: block;
          }
        }
      }
    }
  }
}
