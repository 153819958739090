.home-assistant-page {
  main.content {
    position: relative;
    width: 1340px;

    .honeycomb-container {
      position: absolute;
      opacity: 1;
      top: 62px;
      right: 0px;
      width: 723.6px;

      &.home {
        .hexagon-content-container {
          cursor: pointer;
        }
      }

      .honeycomb li:nth-child(6) {
        z-index: 103;
        opacity: 0.999999;
      }
      .honeycomb li:nth-child(10) {
        z-index: 103;
        opacity: 0.999999;
      }
    }

    .bg-container {
      position: relative;
      width: auto;
      height: 800px;

      img.kitchen {
        position: absolute;
        width: auto;
        height: 800px;
        z-index: 100;
        top: 10px;
      }

      .is-on {
        opacity: 1;
      }
      .is-off {
        opacity: 0;
      }

      .icon {
        transition: all 0.3s ease;
        position: relative;

        .label {
          position: absolute;
          top: 47%;
          left: 51%;
          transform: translate(-50%, -50%);
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          letter-spacing: 0.3px;
        }

        &.is-active {
          svg {
            path {
              stroke: white;
            }
            ellipse {
              stroke: white;
            }
          }
        }

        &.light-percentage,
        &.light-percentage-2,
        &.light-percentage-3 {
          .label {
            color: $orange;
          }
        }

        &.ventillator-percentage,
        &.ventillator-percentage-2 {
          .label {
            color: $purple-3;
          }
        }

        &.water {
          &.is-active {
            svg {
              path {
                fill: $blue;
              }
            }
          }
        }
        &.water-percentage {
          .label {
            color: $blue;
          }
        }

        &.fridge {
          &.is-active {
            svg {
              path {
                fill: $cyan;
              }
              path:nth-of-type(1) {
                fill: $cyan;
              }
            }
          }
        }

        &.fridge-temp,
        &.fridge-temp-goal {
          .label {
            color: $cyan;
          }
        }

        &.stove {
          &.is-active {
            svg {
              path {
                fill: $red;
              }
            }
          }
        }
        &.stove-temp,
        &.stove-gas {
          .label {
            color: $red;
          }
        }

        &.grill {
          &.is-active {
            svg {
              path {
                fill: $bordeaux;
              }
              ellipse {
                stroke: white;
              }
            }
          }
        }
        &.grill-temp,
        &.grill-blow {
          .label {
            color: $bordeaux;
          }
        }
      }

      .light {
        position: absolute;
        top: 84px;
        left: 219px;
        z-index: 101;
        cursor: pointer;
      }

      .light-secondary {
        position: absolute;
        top: 186px;
        left: 175px;
        z-index: 101;
        cursor: pointer;
      }

      .light-outside {
        position: absolute;
        top: 238px;
        left: 533px;
        z-index: 101;
        cursor: pointer;
      }

      .light-percentage {
        position: absolute;
        top: 33px;
        left: 249px;
        z-index: 101;
        cursor: pointer;
      }

      .light-percentage-2 {
        position: absolute;
        top: 240px;
        left: 204px;
        z-index: 101;
        cursor: pointer;
      }

      .light-percentage-3 {
        position: absolute;
        top: 238px;
        left: 473px;
        z-index: 101;
        cursor: pointer;
      }

      .ventillator-1 {
        position: absolute;
        top: 158px;
        left: 339px;
        z-index: 101;
        cursor: pointer;
      }
      .ventillator-2 {
        position: absolute;
        top: 181px;
        left: 50px;
        z-index: 101;
        cursor: pointer;
      }

      .ventillator-percentage {
        position: absolute;
        top: 159px;
        left: 398px;
        z-index: 101;
        cursor: pointer;
      }

      .ventillator-percentage-2 {
        position: absolute;
        top: 128px;
        left: 20px;
        z-index: 101;
        cursor: pointer;
      }

      .water {
        position: absolute;
        top: 397px;
        left: 270px;
        z-index: 101;
        cursor: pointer;
      }

      .water-percentage {
        position: absolute;
        top: 343px;
        left: 299px;
        z-index: 101;
        cursor: pointer;
      }

      .fridge {
        position: absolute;
        top: 524px;
        left: 356px;
        z-index: 101;
        cursor: pointer;
      }

      .fridge-temp {
        position: absolute;
        top: 525px;
        left: 416px;
        z-index: 101;
        cursor: pointer;
      }
      .fridge-temp-goal {
        position: absolute;
        top: 576px;
        left: 386px;
        z-index: 101;
        cursor: pointer;
      }

      .stove {
        position: absolute;
        top: 378px;
        left: 514px;
        z-index: 101;
        cursor: pointer;
      }
      .stove-temp {
        position: absolute;
        top: 378px;
        left: 455px;
        z-index: 101;
        cursor: pointer;
      }
      .stove-gas {
        position: absolute;
        top: 326px;
        left: 487px;
        z-index: 101;
        cursor: pointer;
      }

      .grill {
        position: absolute;
        top: 580px;
        left: 673px;
        z-index: 1001;
        cursor: pointer;
      }
      .grill-temp {
        position: absolute;
        top: 581px;
        left: 735px;
        z-index: 101;
        cursor: pointer;
      }
      .grill-blow {
        position: absolute;
        top: 633px;
        left: 703px;
        z-index: 101;
        cursor: pointer;
      }
    }
  }
}

.unit {
  font-size: 18px;
}

.d-block {
  display: block;
}

.lh-1 {
  line-height: 1;
}

.hexagon-content-container .ranger {
  z-index: 10000;
  opacity: 0.99999;
  position: absolute;
  top: -20px;
  right: 0px;
  overflow: hidden;
  height: 215px !important;
  width: 173px !important;
  opacity: 0;
  .ranger__track {
    height: 122px;
    width: 170px !important;

      .ranger__thumb {
        width: 170px !important;
        height: 170px !important;
        bottom: -100px !important;
      }
  
  }
}

button.test-api {
  position: absolute;
  bottom: 30px;
  right: 30px;
  background-color: aquamarine;
  width: 200px;
  height: 44px;
}

.grill-highlight {
  opacity: 0.9999;
  z-index: 1000;
  opacity: 0.9999;
  position: absolute;
  top: 483px;
  left: 608px;
}
