.hexagon-content-container {
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.back-arrow {
      cursor: pointer;
      h2 {
        font-weight: 400;
        color: $gray;
      }
      &:hover,
      &:focus {
        h2 {
          color: darken($gray, 30);
        }
      }
    }

    h2 {
      margin-top: 10px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.3px;
    }

    .active {
      opacity: 0;
      position: absolute;
      top: -62px;
      z-index: -1;
    }

    .text-state {
      width: 140px;
      height: 65px;
      font-variant-numeric: tabular-nums;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      color: black;
      position: relative;
    }
  }

  img.bg-image {
    opacity: 1;
  }
}

.honeycomb {
  position: relative;

  .hexagon-item {
    cursor: pointer;
    &.grill {
      z-index: 1001 !important;
    }
    position: relative;
    inset: -10px !important;
    img {
      width: 100%;
    }

    .hexagon-content-container {
      transform: scale(0.9);
    }

    &.light-active,
    &.grill-active,
    &.ventillator-active,
    &.fridge-active,
    &.stove-active,
    &.water-active,
    &.music-active,
    &.drawer-active
    {
      .hexagon-content-container {
        width: 75%;
        height: 75%;
        margin: auto;
        display: flex;
        margin-top: 30px;
        transform: scale(0.85);

        .content {
          h2 {
            color: #fff;
          }
          .active {
            opacity: 1;
          }

          .text-state {
            color: white;
          }

          svg:not(.active-icon) {
            stroke: white;
            ellipse,
            circle,
            path {
              stroke: white;
            }
          }
        }
        img.bg-image {
          opacity: 0;
        }
      }
    }
  }
}
