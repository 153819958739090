@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

* {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

body {
  margin: 0;
}
