@import "./parts/variables.scss";
@import "./parts/header";
@import "./parts/main";
@import "./parts/hexagon-content";

.home-assistant-page {
  position: relative;
  width: 1340px;
  height: 800px;
  overflow: hidden;
}
html {
	zoom:75%;
}
html, body {
  font-size: 16px;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
}
