$orange: #F2552C;
$purple: #4D0068;
$purple-2: #814A94;
$purple-3: #7F437E;
$blue: #3C63EF;
$cyan: #1DB9CE;
$bordeaux: #8B265D;
$red: #F22C2C;
$red-strong: #F82121;
$green: #65BE3B;

$gray: rgba(0,0,0,0.55);
$base: #333;
$light-gray: #e4dee6;
$light-orange: hsl(42, 88%, 84%);